import { NgModule } from '@angular/core';
import { StateOptionsComponent } from './state-options.component';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { SubscriptionService } from '../../services/subscription.service';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ StateOptionsComponent ],
  entryComponents: [ StateOptionsComponent ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule
    ],
  providers: [ SubscriptionService ]
})
export class StateOptionsModule {
}
