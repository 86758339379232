import { Pipe, PipeTransform } from '@angular/core';
import { Urls } from '../shared/urls';

@Pipe({
  name: 'profileImage'
})

export class ProfileImagePipe implements PipeTransform {

  constructor( private urls: Urls ) {}

  transform(profileImageId: string, ...args: any[]): any {

    if ( profileImageId !== null ) {

      if ( profileImageId.includes('http') ) {
        console.log("AAA", profileImageId);
        return profileImageId
      } else {
        console.log("BBB", profileImageId);
        return `${ this.urls.downloadFileURL }${ profileImageId }`;
      }
    } else {
      return `./assets/img/posters/profile-placeholder.jpg`;
    }
  }
}
