import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quality'
})
export class QualityPipe implements PipeTransform {

  transform(quality: number, ...args: any[]): any {

    let label = 'UnKnown';

    if ( quality === 999 ) {
      label = 'AUTO';
    } else if ( quality === 2160 ) {
      label = '4K';
    } else if ( quality === 1080 ) {
      label = 'HD+';
    } else if ( quality === 720 ) {
      label = 'High';
    } else if ( quality === 480 ) {
      label = 'Medium';
    } else if ( quality === 320 ) {
      label = 'Low';
    } else if ( quality === 240 ) {
      label = 'Ultra Low';
    }

    return label;
  }

}
