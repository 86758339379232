import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cast'
})
export class CastPipe implements PipeTransform {

  transform(cast: string, ...args: any[]): any {

    cast = cast.trim();
    let castNameList = [];
    let innerHTML: string = null;

    if ( cast.includes(',') ) {

      castNameList = cast.split(',');
    }

    if ( typeof castNameList !== 'undefined' && castNameList !== null && castNameList.length > 0 ) {

      castNameList.forEach(name => {
        name = name.trim();

        if ( name !== null ) {
          innerHTML = `${ innerHTML !== null ? innerHTML : '' } <li> ${ name } </li>`;
        }
      });
    }

    return innerHTML;
  }
}
