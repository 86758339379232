import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { SubscriptionService } from '../../services/subscription.service';
import { Location } from '../../models/subscription/location';

@Component({
  selector: 'app-state-options',
  templateUrl: './state-options.component.html',
  styleUrls: ['./state-options.component.scss']
})
export class StateOptionsComponent implements OnInit {

  isIndia: boolean = true;
  locations: any = [];

  constructor(
    private matDialogRef: MatDialogRef<StateOptionsComponent>,
    private sanitizer: DomSanitizer,
    private subscriptionService: SubscriptionService) {
    this.fetchForCountryStateCity();
  }

  ngOnInit() {
  }

  @Output() sendState : EventEmitter <string> = new EventEmitter<string>();
  public sendRecord(state : string ) {
    this.sendState.emit(state);
  }

  fetchForCountryStateCity(): void {
    const subscription = this.subscriptionService.getUsersLocation()
      .subscribe(locations => {
        this.locations = locations
        if(this.locations[0].countryName == "India"){
          this.isIndia = true;
        }
        console.log(this.locations)
      });
  }

  getStateValue(state: string): void {

    sessionStorage.setItem('state', state);
    this.matDialogRef.close();
  }

}
