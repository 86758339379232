import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStoreDialogComponent } from './app-store-dialog.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [ AppStoreDialogComponent ],
  entryComponents: [ AppStoreDialogComponent ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class AppStoreDialogModule { }
