import { Injectable } from '@angular/core';
import { CurrentUser } from '../models/user/current-user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SuccessResponse } from '../models/response-types/success-response';
import { Urls } from '../shared/urls';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, private urls: Urls) {
  }

  // GET: - Fetch For Currently Logged In User Details
  // ----------------------------------------------------------------
  fetchForCurrentUserDetails(id: any, contact: string, password: string): Observable<CurrentUser> {
    //var userId = sessionStorage.getItem('regID');
    var userId = localStorage.getItem('regID');
    const prev_regID = sessionStorage.getItem('prev_regID');
    const reg_Mobile = sessionStorage.getItem('regMobileNumber');
    console.log('sendotpfrom ' + userId);
    const params: HttpParams = new HttpParams()
      .set('id', userId)
      .set('token', 'TokenID')
      .set('contact', contact)
      .set('password', password)
      .set('prev_regID', prev_regID)
      .set('reg_Mobile', contact);

    // console.log(params);
    return this.httpClient
      .post<CurrentUser>(this.urls.fetchForCurrentUserDetailsURL, null, { params });
  }

  // POST: - Change User Profile Picture and Full Name
  // ----------------------------------------------------------------
  changeUserProfileDetails(currentUser: CurrentUser): Observable<SuccessResponse> {

    return this.httpClient
      .post<SuccessResponse>(this.urls.changeUserProfileDetailsURL, currentUser);
  }

  // POST: - Change User Password
  // ----------------------------------------------------------------
  changeUserPassword(userCredentials: { id: any, oldPassword: string, newPassword: string, confirmPassword: string }): Observable<SuccessResponse> {
    const userId = localStorage.getItem('user_id');
    const oldPassword = userCredentials.oldPassword;
    const newPassword = userCredentials.newPassword;
    const confirmPassword = userCredentials.confirmPassword;

    if (oldPassword === newPassword) {

      return throwError({ status: -1, errorMessage: `You have already used this password, try something new` });
    } else if (newPassword !== confirmPassword) {

      return throwError({ status: -1, errorMessage: `New and Confirm Passwords do not match` });
    }

    const params: HttpParams = new HttpParams()
      .set('id', userId)
      .set('oldPassword', oldPassword)
      .set('newPassword', newPassword)
      .set('confirmPassword', confirmPassword);

    return this.httpClient
      .post<SuccessResponse>(this.urls.changeUserPasswordURL, null, { params });
  }

  // POST: - Update User FCM token
  // ----------------------------------------------------------------
  updateUserFCMToken(fcmToken: string): Observable<SuccessResponse> {

    const params = new HttpParams()
      .set('fcmToken', fcmToken)
      .set('tokenPlatform', 'web');

    return this.httpClient
      .post<SuccessResponse>(this.urls.updateUserFCMTokenURL, null, { params });
  }
}
