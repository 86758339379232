import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  message: string;
  subscription: Subscription = null;

  constructor( @Inject(MAT_DIALOG_DATA) public dialogData: { message: string },
               private matDialogRef: MatDialogRef<ConfirmationDialogComponent> ) {

    this.message = this.dialogData.message;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.subscription = timer(99999)
      .subscribe( () => this.matDialogRef.close('auto_cancel') );
  }

  ngOnDestroy(): void {

    if ( this.subscription !== null ) { this.subscription.unsubscribe(); }
  }
}
