import { Injectable, Optional } from '@angular/core';
import { AppRoutes } from '../models/core/enums/app-routes.enum';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from './shared.service';
import { tap } from 'rxjs/internal/operators';
import { StorageKeys } from '../models/core/enums/storage-keys.enum';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

  constructor( private authStorage: OAuthStorage,
               private errorHandler: OAuthResourceServerErrorHandler,
               private matDialog: MatDialog,
               @Optional() private moduleConfig: OAuthModuleConfig,
               private router: Router,
               private sharedService: SharedService) {
  }

  private checkUrl(url: string): boolean {
    const found = this.moduleConfig.resourceServer.allowedUrls
      .find(allowedUrl => url.toLowerCase().includes(allowedUrl.toLowerCase()));
    return !!found;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const url = req.url.toLowerCase();

    if ( !this.moduleConfig ) {
      return next.handle(req);
    }
    if ( !this.moduleConfig.resourceServer ) {
      return next.handle(req);
    }
    if ( !this.moduleConfig.resourceServer.allowedUrls ) {
      return next.handle(req);
    }
    if ( !this.checkUrl(url) ) {
      return next.handle(req);
    }

    if ( sessionStorage.getItem(StorageKeys.ACCESS_TOKEN) !== null ) {

      const token = sessionStorage.getItem(StorageKeys.ACCESS_TOKEN);
      const header = 'Bearer ' + token;

      const headers = req.headers.set('Authorization', header);

      req = req.clone({ headers });
    }

    return next.handle(req)
      .pipe(tap(event => {
        // Succeeds when there is a response; ignore other events
      }, error => {
        // Operation failed; error is an HttpErrorResponse
        if ( error.status === 401 || error.status === 403 ) {
          // Closing Dialogs i.e MatDialog & MatBottomSheet
          this.matDialog.closeAll();
          // this.sharedService.updateLoginState(false);
          this.router.navigate([ AppRoutes.LOGIN ]).then();
        }
      }));
  }

}
