import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import formatTime from '../../assets/js/format-time.js';

@Pipe({
  name: 'formatPlayerTime'
})
export class FormatPlayerTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    const time = typeof value === 'undefined' || value === null || isNaN(value) ? 0 : value;

    return formatTime(time);
  }

}
