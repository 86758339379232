import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CastPipe } from './cast.pipe';
import { FormatPlayerTimePipe } from './format-player-time.pipe';
import { PricePipe } from './price.pipe';
import { ProfileImagePipe } from './profile-image.pipe';
import { QualityPipe } from './quality.pipe';

@NgModule({
    declarations: [
      CastPipe,
      FormatPlayerTimePipe,
      PricePipe,
      ProfileImagePipe,
      QualityPipe

    ],
  exports: [
    CastPipe,
    FormatPlayerTimePipe,
    PricePipe,
    ProfileImagePipe,
    QualityPipe
  ],
    imports: [
        CommonModule
    ]
})
export class PipesModule { }
