import { Component, OnInit } from '@angular/core';

enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WINDOWS_PHONE = 'WINDOWS_PHONE'
}

@Component({
  selector: 'app-app-store-dialog',
  templateUrl: './app-store-dialog.component.html',
  styleUrls: ['./app-store-dialog.component.scss']
})
export class AppStoreDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    const html = document.getElementById('html');
    html.style.top = null;
    html.style.left = null;

    if (html.classList.contains('cdk-global-scrollblock')) {

      html.classList.remove('cdk-global-scrollblock');
    }
  }
}
