import { ModuleWithProviders, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogModule } from '../dialogs/confirmation-dialog/confirmation-dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatButtonModule, MatCheckboxModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatInputModule,
  MatProgressSpinnerModule, MatRippleModule, MatSelectModule, MatStepperModule, MatTabsModule } from '@angular/material';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../pipes/pipes.module';

import { SharedService } from './shared.service';
import { Urls } from './urls';

const firebaseConfig = {
  apiKey: 'AIzaSyBtGhDmLdhIBJb97d81dVknjx9TgUyAlZs',
  authDomain: 'kidullan-bd1b8.firebaseapp.com',
  databaseURL: 'https://kidullan-bd1b8.firebaseio.com',
  projectId: 'kidullan-bd1b8',
  storageBucket: 'kidullan-bd1b8.appspot.com',
  messagingSenderId: '619839761777',
  appId: '1:619839761777:web:2ab0443cced018f8c4dad0',
  measurementId: 'G-3ZJNFSXB7B'
};

@NgModule({
  declarations: [],
  exports: [
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    FormsModule,
    LazyLoadImageModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatStepperModule,
    MatTabsModule,
    NgbDropdownModule,
    NgbModule,
    PipesModule,
    ReactiveFormsModule
  ],
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    CommonModule,
    ConfirmationDialogModule
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {

    return {
      ngModule: SharedModule,
      providers: [
        ScreenTrackingService,
        SharedService,
        Urls,
        UserTrackingService
      ]
    };
  }
}
