import { NgModule } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

import { CommonModule } from '@angular/common';
import { MatButtonModule, MatDialogModule } from '@angular/material';

@NgModule({
  declarations: [ ConfirmationDialogComponent ],
  entryComponents: [ ConfirmationDialogComponent ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class ConfirmationDialogModule {
}
