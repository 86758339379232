import { Injectable } from '@angular/core';
import { CountryCode } from '../models/ui-content/country-code';
import { ContentType } from '../models/ui-content/enums/content-type.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServerStatus } from '../models/ui-content/server-status';
import { UiContent } from '../models/ui-content/ui-content';
import { Urls } from '../shared/urls';

@Injectable({
  providedIn: 'root'
})
export class UiContentService {

  constructor( private httpClient: HttpClient, private urls: Urls ) { }

  fetchPrivacyUIContent(page_id): Observable<string> {
    let HTTPOptions:Object = {
      responseType: 'text/html'
   }
    return this.httpClient.get<string>( this.urls.fetchPrivacyUIContent+'/'+page_id, HTTPOptions);
  }

  // GET: - Fetch for Country Code List
  // ----------------------------------------------------------------------------------
  fetchForCountryCodeList(): Observable<CountryCode[]> {

    return this.httpClient
      .get<CountryCode[]>(this.urls.fetchForCountryCodeListURL);
  }

  // GET: - Fetch For UI Content of TERMS AND CONDITION | REFUND | PRIVACY POLICY | ABOUT US
  // ----------------------------------------------------------------------------------
  fetchForUIContent(clientContentEnum: ContentType): Observable<UiContent> {

    const params = new HttpParams()
      .set( 'clientContentEnum', clientContentEnum );

    return this.httpClient
      .get<UiContent>( this.urls.fetchForUIContentURL, { params } );
  }

  // GET: - Fetch For Server Status
  // ----------------------------------------------------------------------------------
  fetchForServerStatus(): Observable<ServerStatus> {

    return this.httpClient
      .get<ServerStatus>( this.urls.fetchForServerStatusURL );
  }
}


